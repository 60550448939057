@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@vaimo-int/aem-pwa-common-components/dist/styles/vars';
@use '@/styles/resources/responsive';

.productCollapseSection {
    font-size: 0.875rem;
    grid-column: 1/-1;

    @include vars.mq-small {
        border-bottom: 1px solid var(--color--gray-400);
        padding: 0.75rem 0;
    }
}

.productCollapseSectionTitle {
    align-items: center;
    display: flex;
    justify-content: space-between;

    @include vars.mq-medium-up {
        margin-bottom: 1rem;
    }
}

.productCollapseSectionTitleIcon {
    align-content: center;
    display: flex;
    font-size: 1.25rem;
    transform: rotate(90deg);
    transition: transform 0.2s ease-in-out;

    @include responsive.media('>tablet') {
        display: none;
    }
}

.productCollapseSectionContent {
    display: none;

    @include responsive.media('>tablet') {
        display: block;
        padding-top: 0;
    }
}

.productCollapseSectionIsActive {
    .productCollapseSectionContent {
        display: block;
        padding-top: 0.5rem;
    }

    .productCollapseSectionTitleIcon {
        transform: rotate(-90deg);
    }
}
