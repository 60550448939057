@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/responsive';

.root {
    @include responsive.media('<tablet') {
        background-attachment: scroll;
        flex-basis: 100%;
        padding: 0 !important;
    }
}
