@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/fonts';


.root {
    h2 {
        display: none;
    }

    :global {
        .collapse-container__heading {
            align-items: center;
            border-bottom: 0.1rem solid colors.$border-color--primary;
            cursor: pointer;
            display: flex;
            font-size: 1rem;
            font-weight: fonts.$font-weight--bold;
            gap: 1rem;
            justify-content: space-between;
            margin: 0 !important;
            padding: 0.875rem;
            position: relative;

            &:after {
                content: url(data:image/svg+xml,%3Csvg%20stroke%3D%22currentColor%22%20fill%3D%22none%22%20stroke-width%3D%222%22%20viewBox%3D%220%200%2024%2024%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20height%3D%221em%22%20width%3D%221em%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpolyline%20points%3D%226%209%2012%2015%2018%209%22%3E%3C%2Fpolyline%3E%3C%2Fsvg%3E);
                transition: transform 0.2s;
            }
        }

        .inactive-collapse {
            background-color: colors.$color--white;
        }

        .active-collapse {
            background-color: colors.$border-color--primary;

            &:after {
                transform: rotate(-180deg);
            }
        }

        .collapse-container__body {
            padding: 1rem;

            p {
                margin-bottom: 0 !important;
            }
        }
    }
}
