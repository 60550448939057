@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
.yotpo {
    align-items: center;
    display: flex;
    font-size: 0.875rem;
    gap: 0.5rem;
}

.yotpoRatings {
    display: flex;
    gap: 0.1rem;
}

.yotpoRatingsStar {
    fill: var(--brand-color--primary);
    height: 0.75rem;
    width: 0.75rem;
}

.yotpoRatingsStarFull {
    composes: yotpoRatingsStar;
    fill: var(--brand-color--primary);
}

.yotpoRatingsStarEmpty {
    composes: yotpoRatingsStar;
    fill: #d6d6d6;
}

.yotpoRatingsStarStroke {
    composes: yotpoRatingsStar;
    fill: var(--brand-color--primary);
}
