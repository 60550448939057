@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@vaimo-int/aem-pwa-common-components/dist/styles/vars';
@use '@/styles/resources/colors';
@use '@/styles/resources/variables';

div.textContent {
    display: block !important;
}

.readMoreButton {
    color: colors.$brand-color--primary;
    margin-top: 0.5rem;
    text-decoration: underline;
}

.container {
    margin: 0 auto !important;
    max-width: variables.$max-screen-width !important;
    padding: 0 variables.$mobile-page-gutter !important;
    width: 100% !important;

    @include vars.mq-medium-up {
        padding: 0 variables.$desktop-page-gutter !important;
    }
}
