@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@vaimo-int/aem-pwa-common-components/dist/styles/vars';
@use '@/styles/resources/fonts';
@use '@/styles/resources/colors';
@use '@/styles/resources/responsive';

.root {
    :global {
        p {
            a {
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        .table-label {
            font-size: 1rem;
            font-weight: fonts.$font-weight--medium;
            padding: 1rem 0;
            text-transform: uppercase;
        }

        .tabs-data {
            display: none;

            &--active {
                display: block;
                padding-bottom: 2rem;
            }
        }

        .sizeguide-table {
            display: block;

            &__thead {
                display: none;
            }

            &__row {
                display: block;
                padding: 2rem 0 0;

                &:after {
                    display: none;
                }
            }

            &__tbody {
                display: block;
            }

            &__item {
                background-color: colors.$color--gray-lightest;
                border-bottom: none;
                display: flex;
                overflow: hidden;
                position: relative;
                text-align: center;

                & + .sizeguide-table__item {
                    padding-bottom: 0;
                    padding-top: 0;
                }

                &:before {
                    background-color: colors.$color--gray-lightest;
                    content: attr(data-th);
                    float: left;
                    font-weight: fonts.$font-weight--medium;
                    padding: 1.4rem;
                    text-transform: uppercase;
                    width: 50%;
                    word-wrap: break-word;
                }

                &:first-child {
                    display: block;

                    > span {
                        background-color: colors.$color--gray-lightest;
                        font-size: 1.2rem;
                        font-weight: fonts.$font-weight--medium;
                        padding-left: 0.5rem;
                        text-align: left;
                    }

                    &:before {
                        font-size: 1.2rem;
                        font-weight: fonts.$font-weight--medium;
                        padding-right: 0.1rem;
                        text-align: right;
                    }

                    &:after {
                        content: ' - ';
                        display: inline-block;
                        left: 50%;
                        position: absolute;
                        top: 34%;
                    }
                }
            }

            &__txt {
                background-color: colors.$color--white;
                float: right;
                padding: 1.4rem 1rem;
                width: 50%;
                word-wrap: break-word;
            }
        }

        .size-chart {
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            margin-bottom: 2rem;

            &__col {
                display: flex;
                flex-direction: column;
                gap: 0.75rem;
            }
        }

        .tabs {
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;
            list-style: none;
            margin: 0 !important;
            padding: 0;

            &__item {
                &::before {
                    display: none;
                }
            }

            &__link {
                background-image: none;
                border: 1px solid colors.$border-color--dark;
                display: block;
                font-weight: fonts.$font-weight--bold;
                padding: 0.5rem 0.75rem;
                text-decoration: none;

                &:hover {
                    background-color: colors.$button-background-color--disabled;
                    color: colors.$text-color--primary;
                }

                &--active {
                    background-color: colors.$button-background-color--disabled;
                    color: colors.$text-color--white;

                    &:hover {
                        background-color: colors.$button-background-color--disabled;
                        color: colors.$text-color--white;
                    }
                }

                &:active {
                    background-color: colors.$button-background-color--disabled;
                    color: colors.$text-color--white;
                }
            }
        }

        .heading--subsection {
            font-size: 1.25rem;
        }

        .heading--smallest {
            padding-bottom: 0;
        }

        .sizeguide-switcher {
            display: flex;
            gap: 1rem;
            list-style: none;
            padding: 0;
            text-transform: uppercase;

            &__link {
                text-decoration: none;
            }

            &__link--active {
                text-decoration: underline;
            }
        }

        .sizeguide-info__row {
            display: flex;
            flex-direction: column;
            gap: 2rem;
        }

        .sizeguide-info.sizeguide-info__row {
            border-top: 1px solid colors.$border-color--tertiary;
            display: flex;
            flex-direction: column;
            gap: 0;
            padding-top: 1.5rem;

            h2 {
                display: block;
            }
        }
    }
}

@include responsive.media('>tablet') {
    .root {
        :global {
            .size-chart {
                flex-direction: row;
                flex-wrap: wrap;
                gap: 2rem;
            }

            ul.sizeguide-switcher {
                padding-bottom: 1rem;
            }

            .sizeguide-info__row {
                flex-direction: row;
                gap: 2rem;
            }

            .heading--smallest {
                padding-bottom: 1rem;
            }

            .sizeguide-table {
                border: 1px solid colors.$border-color--tertiary;
                display: table;
                table-layout: fixed;
                width: 100%;

                &__thead {
                    display: table-header-group;
                    text-transform: uppercase;
                }

                &__row {
                    border-top: 1px solid colors.$border-color--tertiary;
                    display: table-row;
                    margin: 0;
                }

                &__tbody {
                    display: table-row-group;
                }

                &__item {
                    background-color: colors.$color--white;
                    display: table-cell !important;

                    &:first-child {
                        & > span {
                            background-color: colors.$color--white;
                            font-size: 0.875rem;
                            font-weight: fonts.$font-weight--regular;
                            padding: 0;
                            text-align: center;
                        }

                        &:after {
                            display: none;
                        }
                    }

                    & + .sizeguide-table__item {
                        padding: 1rem 0;
                    }

                    &:before,
                    &:after {
                        display: none;
                    }

                    &--heading {
                        background-color: colors.$color--gray-lightest;
                        padding: 1rem 0;
                    }
                }

                &__txt {
                    background-color: colors.$color--white;
                    float: none;
                    padding: 0;
                }
            }
        }
    }
}

@include responsive.media('>desktop-xxl') {
    .root {
        :global {
            .size-chart {
                flex-wrap: nowrap;
                gap: 7rem;
            }
        }
    }
}
