@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/variables';
@use '@/styles/resources/responsive';


.root {
    --leftViewportElement: 0;
    --scrollBarWidth: var(--global-scrollbar-width, 0px);

    @include responsive.media('<tablet') {
        background-attachment: scroll !important;
    }
}

.contained {
    composes: root;
    margin-left: auto !important;
    margin-right: auto !important;
    max-width: variables.$max-screen-width;
    width: 100%;
}

.fullBleed {
    composes: root;
    margin-left: 50%;
    transform: translateX(-50%);
    width: calc(100vw - var(--scrollBarWidth));
}

.videoOverlay {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}
