@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@vaimo-int/aem-pwa-common-components/dist/styles/vars';
@use '@/styles/resources/colors';
@use '@/styles/resources/fonts';
@use '@/styles/resources/z-index';
@use '@/styles/resources/variables';

.topPanelWrapper {
    background-color: colors.$color--white;
    border: 1px solid colors.$border-color--tertiary;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    bottom: 0;
    box-shadow: variables.$material--box-shadow;
    left: 0;
    opacity: 1;
    padding: 0.5rem;
    position: fixed;
    right: 0;
    transform: translate3d(0, 0, 0);
    transition-duration: 0.2s;
    transition-property: opacity, transform;
    transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
    z-index: z-index.$index--omnipresent-add-to-cart;

    @include vars.mq-medium-up {
        display: none;
    }
}
