@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/fonts';

.root {
    word-wrap: break-word;

    img {
        max-width: 100%;
    }

    h1, h2, h3, h4, h5, h6 {
        font-family: Dinot Pro, sans-serif;
        font-weight: 700;
        margin: 0;
        text-transform: uppercase;
    }

    h1 {
        font-size: $fontSize-6xl;
        line-height: $heading__h1_line-height;
    }

    h2 {
        font-size: $fontSize-5xl;
        line-height: $heading__h2_line-height;
    }

    h3 {
        font-size: $heading__h3_font-size;
        line-height: $heading__h3_line-height;
    }

    h4 {
        font-size: $fontSize-2xl;
        line-height: $heading__h4_line-height;
    }

    h5 {
        font-size: $fontSize-base;
        line-height: $heading__h5_line-height;
    }

    h6 {
        font-size: $fontSize-sm;
        line-height: $heading__h5_line-height;
    }
}
