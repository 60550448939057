@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/responsive';

.productFreeShippingLabel {
    color: colors.$text-color--primary;
    margin-top: 0.125rem;
    padding-left: 1.3rem;
    padding-top: 0.625rem;

    position: relative;

    @include responsive.media('>tablet') {
        margin-top: 0.25rem;
    }

    &::before {
        border: solid colors.$text-color--primary;
        border-width: 0 2px 2px 0;
        content: '';
        height: 0.7rem;
        left: 0.3rem;
        position: absolute;
        top: 0.75rem;
        transform: rotate(45deg);
        width: 0.3rem;
    }
}
